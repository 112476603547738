<template>
  <div>
    <v-dialog
      v-model="dialog"
      max-width="80vw"
      max-height="80vh"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-card class="overflow-hidden">
        <v-row class="ma-0 ml-4" justify="space-between">
          <span
            class="col-10 px-0 grey--text text--darken-4 text-h6 text-truncate"
            >No Response Ticket</span
          >
          <v-btn
            class="mr-2 my-auto"
            aria-label="cross"
            icon
            @click="closeDialog()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-row>
        <v-row no-gutters>
          <v-divider></v-divider>
        </v-row>
        <v-row no-gutters class="mx-4" justify="center">
          <v-radio-group v-model="noResponseOption" row>
            <v-radio
              label="BY FACILITY OPERATOR"
              value="byFacilityOperator"
            ></v-radio>
            <v-radio label="BY FACILITY TYPE" value="byFacilityType"></v-radio>
          </v-radio-group>
        </v-row>
        <v-row no-gutters class="mx-4">
          <div v-if="noResponseOption === 'byFacilityOperator'">
            <p>
              Select the reason next to each Facility Operator as appropriate to
              indicate the need for the No Response Notice. Only those Facility
              Operators with reasons selected will receive the NoResponse
              Notice. Choose the "By Facility Type" option only if the specific
              facilities are not known. Click on the Reset button to clear the
              row of any previous selection. Click on the information in the
              Facility type(s) and Current Response columns for additional
              information.
            </p>
            <NoResponseByFacilityOperatorUtah
              :nrsp-members-data="nrspMembersData"
              :is-meet-eligible="isMeetEligible"
              @update-members-data="handleUpdateMembersToNotify"
            />
          </div>
          <div v-else>
            <p>
              Select the reason next to each Facility Type as appropriate to
              indicate the need for the No Response Notice. Only those Facility
              Operators having a Facility Type with reasons selected will
              receive the No Response Notice. Click on the Reset button to clear
              the row of any previous selection. Click on a facility type other
              than "All Facilities" to see which Facility Operators are
              included.
            </p>
            <NoResponseByFacilityTypeUtah
              @update-facilities-to-notify="handleUpdateFacilitiesToNotify"
              :nrsp-members-data="nrspMembersData"
            />
          </div>
        </v-row>

        <v-row no-gutters class="mx-4 mt-4 mb-4 d-flex flex-column">
          <div class="font-weight-medium">Choose or enter caller</div>

          <v-radio-group v-model="callerOptions" @change="updateCaller" row>
            <v-radio
              label="Other, enter below"
              value="other, enter below"
            ></v-radio>
            <v-radio label="Original caller" value="original caller"></v-radio>
            <v-radio
              label="Original contact"
              value="original contact"
            ></v-radio>
          </v-radio-group>
        </v-row>
        <v-row no-gutters class="mx-4 mt-4" justify="center">
          <v-text-field
            v-model="caller"
            :disabled="
              callerOptions === 'original caller' ||
              callerOptions === 'original contact'
            "
            outlined
            dense
            required
            :label="'Caller'"
            class="px-2"
            :error-messages="callerError"
            @input="$v.caller.$touch()"
            @blur="$v.caller.$touch()"
          >
          </v-text-field>
          <v-text-field
            v-model="callerPhone"
            :disabled="
              callerOptions === 'original caller' ||
              callerOptions === 'original contact'
            "
            outlined
            dense
            required
            label="Caller Phone"
            :error-messages="callerPhoneError"
            @input="$v.callerPhone.$touch()"
            @blur="$v.callerPhone.$touch()"
            class="px-2"
          >
          </v-text-field>
          <v-text-field
            v-model="extention"
            outlined
            dense
            :disabled="
              callerOptions === 'original caller' ||
              callerOptions === 'original contact'
            "
            label="Extention"
            class="px-2"
            @keypress="isNumber($event)"
          >
          </v-text-field>
        </v-row>

        <v-row no-gutters class="mx-4 mt-4 mb-4 d-flex flex-column">
          <div class="font-weight-medium">
            Who should the facility operators contact when responding to this
            notice?
          </div>

          <v-radio-group v-model="contactOptions" @change="updateContact" row>
            <v-radio
              label="Other, enter below"
              value="other, enter below"
            ></v-radio>
            <v-radio label="Original caller" value="original caller"></v-radio>
            <v-radio
              label="Original contact"
              value="original contact"
            ></v-radio>
            <v-radio
              :disabled="caller === '' || callerPhone === ''"
              label="Caller Entered Above"
              value="caller entered above"
            ></v-radio>
          </v-radio-group>
        </v-row>
        <v-row no-gutters class="mx-4 mt-4 pb-16" justify="center">
          <v-text-field
            v-model="contact"
            outlined
            dense
            required
            :label="'Contact'"
            class="px-2"
            :disabled="
              contactOptions === 'original caller' ||
              contactOptions === 'original contact' ||
              contactOptions === 'caller entered above'
            "
            :error-messages="contactError"
            @input="$v.contact.$touch()"
            @blur="$v.contact.$touch()"
          >
          </v-text-field>
          <v-text-field
            v-model="contactPhone"
            outlined
            dense
            required
            label="Contact Phone"
            class="px-2"
            :disabled="
              contactOptions === 'original caller' ||
              contactOptions === 'original contact' ||
              contactOptions === 'caller entered above'
            "
            :error-messages="contactPhoneError"
            @input="$v.contactPhone.$touch()"
            @blur="$v.contactPhone.$touch()"
          >
          </v-text-field>
          <v-text-field
            v-model="contactPhoneExtention"
            outlined
            dense
            label="Extention"
            class="px-2"
            :disabled="
              contactOptions === 'original caller' ||
              contactOptions === 'original contact' ||
              contactOptions === 'caller entered above'
            "
            @keypress="isNumber($event)"
          >
          </v-text-field>
        </v-row>

        <v-row class="mb-2" v-if="!$vuetify.breakpoint.smAndDown">
          <v-divider></v-divider>
        </v-row>
        <v-card-actions v-if="!$vuetify.breakpoint.smAndDown">
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            outlined
            class="text-none rounded-lg elevation-0"
            depressed
            width="90"
            @click="closeDialog"
          >
            Cancel
          </v-btn>

          <v-btn
            class="primary text-none rounded-lg elevation-0"
            width="90"
            depressed
            @click="openPreviewDialog"
            :disabled="
              $v.$invalid ||
              (parentMembersToNotify.length === 0 &&
                noResponseOption === 'byFacilityOperator') ||
              (parentFacilitiesToNotify.length === 0 &&
                noResponseOption === 'byFacilityType')
            "
          >
            Preview
          </v-btn>
        </v-card-actions>
        <v-bottom-navigation
          v-if="$vuetify.breakpoint.smAndDown"
          fixed
          horizontal
          height="70"
          class="px-2 py-4"
          ><v-row justify="center" no-gutters>
            <v-btn
              color="primary"
              outlined
              class="mr-2 text-none rounded-lg elevation-0"
              width="50%"
              @click="closeDialog"
            >
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              class="text-none font-weight-regular grey--text rounded-lg elevation-0 ml-2"
              width="50%"
              depressed
              @click="openPreviewDialog"
              :disabled="
              $v.$invalid ||
              (parentMembersToNotify.length === 0 &&
                noResponseOption === 'byFacilityOperator') ||
              (parentFacilitiesToNotify.length === 0 &&
                noResponseOption === 'byFacilityType')
            "
              ><span class="white--text text-none">Preview</span>
            </v-btn>
          </v-row></v-bottom-navigation
        >
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPreviewDialog"
      max-width="40vw"
      max-height="80vh"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      class="white-bg-dialog"
    >
      <v-card>
        <v-card-text class="font-weight-medium pt-5 pb-0">
          <p>
            Please review the following information that will be added to the
            Locate Request and sent only to the Facility Operators included in
            the text information below.
          </p>
          <v-divider class="my-2"></v-divider>
          <v-row no-gutters class="ma-4 font-weight-bold" justify="center"
            >NO RESPONSE NOTICE</v-row
          >
          <p>AS PER {{ caller }}:</p>
          <p>
            {{
              membersToNotify.length !== 0
                ? membersToNotify
                    .map((e) => {
                      return `${e.name} : ${e.reason};`;
                    })
                    .join("  ")
                : parentFacilitiesToNotify
                    .map((e) => {
                      return `${e.facilityType} : ${e.reason};`;
                    })
                    .join("  ")
            }}<br />PLEASE MARK ASAP OR CONTACT {{ contact }}
            {{ contactPhone }} BY {{ date.noResponseDate }}.
          </p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            class="text-none mr-1"
            @click="noResponseTicketSubmit()"
            :disabled="submitButtonClicked"
            >Submit
            <v-progress-circular
              v-if="submitButtonClicked"
              indeterminate
              size="20"
              color="white"
              class="ml-2"
            ></v-progress-circular>
          </v-btn>
          <v-btn
            color="primary"
            outlined
            class="text-none"
            @click="closePreviewDialog"
            >Back</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { helpers } from "vuelidate/lib/validators";
import { maxLength, required } from "vuelidate/lib/validators";
import NoResponseByFacilityOperatorUtah from "./NoResponseByFacilityOperatorUtah.vue";
import NoResponseByFacilityTypeUtah from "./NoResponseByFacilityTypeUtah.vue";
import { formatTicketDateTime } from "@/store/utils/utils.js";

const phone = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
const ext = helpers.regex(
  "serial",
  /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/
);
export default {
  mixins: [validationMixin],
  components: {
    NoResponseByFacilityOperatorUtah: () =>
      import("./NoResponseByFacilityOperatorUtah.vue"),
    NoResponseByFacilityTypeUtah: () =>
      import("./NoResponseByFacilityTypeUtah.vue"),
  },
  validations() {
    return {
      remarks: { maxLength: maxLength(4096) },
      contact: { required },
      caller: { required },
      callerPhone: { required, phone },
      contactPhone: { required, phone },
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: () => false,
    },
    secondaryFunctionsAllowedData: {
      type: Object,
      default: () => {},
    },
    ticketData: {
      type: Object,
      default: () => {},
    },
    isMeetEligible: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      contact: "",
      parentMembersToNotify: [],
      parentFacilitiesToNotify: [],
      selectedMembersToAdd: [],
      remarks: "",
      searchCountyMembers: null,
      membersToAddList: [],
      submitButtonClicked: false,
      noResponseOption: "byFacilityOperator",
      callerOptions: "",
      contactOptions: "",
      contactPhone: "",
      caller: "",
      callerPhone: "",
      contactPhoneExtention: "",
      nrspMembersData: [],
      showPreviewDialog: false,
      extention: "",
      callerEnteredAbove: "",
      date: "",
      membersToNotify: [],
    };
  },
  async created() {
    this.nrspMembersData = [
      ...this.secondaryFunctionsAllowedData.nrspMembers,
    ].filter(
      (member) =>
        member.show && member.response !== "777" && member.response !== "888"
    );
    if (this.ticketData && this.ticketData.center) {
      await this.getMembersToAdd();
    }
  },
  watch: {
    async ticketData() {
      await this.getMembersToAdd();
    },
    noResponseOption() {
      this.parentMembersToNotify = [];
      this.membersToNotify = [];
      this.parentFacilitiesToNotify = [];
    },
  },
  methods: {
    formatTicketDateTime(dateString) {
      return formatTicketDateTime(dateString);
    },
    updateCaller() {
      if (this.callerOptions === "original caller") {
        this.caller = this.ticketData.caller;
        this.callerPhone = this.ticketData.caller_phone;
      } else if (this.callerOptions === "original contact") {
        this.caller = this.ticketData.contact;
        this.callerPhone = this.ticketData.contact_phone;
      } else {
        this.caller = "";
        this.callerPhone = "";
      }
    },
    updateContact() {
      if (this.contactOptions === "original caller") {
        this.contact = this.ticketData.caller;
        this.contactPhone = this.ticketData.caller_phone;
      } else if (this.contactOptions === "original contact") {
        this.contact = this.ticketData.contact;
        this.contactPhone = this.ticketData.contact_phone;
      } else if (this.contactOptions === "caller entered above") {
        this.contact = this.caller;
        this.contactPhone = this.callerPhone;
        this.contactPhoneExtention = this.extention;
      } else {
        this.contact = "";
        this.contactPhone = "";
      }
    },

    closePreviewDialog() {
      this.membersToNotify = [];
      this.showPreviewDialog = false;
    },
    handleUpdateMembersToNotify(updatedMembers) {
      this.parentMembersToNotify = updatedMembers;
    },
    handleUpdateFacilitiesToNotify(updatedFacilities) {
      this.parentFacilitiesToNotify = updatedFacilities;
    },
    async noResponseTicketSubmit() {
      this.submitButtonClicked = true;
      let noResponseTicketPayload = {
        ticket_id: this.ticketData.ticket_id,
      };

      let apiData = {
        data: {
          caller: this.caller,
          contact: this.contact,
          contactPhone: this.contactPhone,
          contactPhoneExt: this.contactPhoneExtention,
          started: new Date().toISOString(),
        },
        ticket: this.ticketData.ticket_id,
        center: this.ticketData.centerData.center,
      };

      if (this.parentMembersToNotify && this.parentMembersToNotify.length > 0) {
        apiData.data.membersToNotify = this.parentMembersToNotify;
      }
      if (
        this.parentFacilitiesToNotify &&
        this.parentFacilitiesToNotify.length > 0
      ) {
        apiData.data.facilitiesToNotify = this.parentFacilitiesToNotify;
      }
      apiData.ticket = this.ticketData.ticket;

      noResponseTicketPayload.apiData = apiData;
      let previousRevision = this.ticketData.revision;
      const updateTicketResp = await this.$store.dispatch(
        "revisionNoResponseTicket",
        noResponseTicketPayload
      );

      if (updateTicketResp.status === "error") {
        this.$emit("errorSubmitTicket", updateTicketResp);
      } else {
        this.$emit(
          "noResponseTicketSuccess",
          this.ticketData.ticket,
          previousRevision
        );
      }
      this.closeDialog();
      this.submitButtonClicked = false;
    },
    async openPreviewDialog() {
      this.date = await this.$store.dispatch("legalDatesData", {
      started: new Date().toISOString(),
      format: "ISO",
      ticketPriority: this.ticketData?.priority,
      ticketType: this.ticketData?.type,
      center: this.ticketData?.centerData?.center,
    });
      this.showPreviewDialog = true;
      this.parentMembersToNotify.map((member) => {
        let memberObject = this.nrspMembersData.find(
          (e) => e.mbcode === member.memberCode
        );
        this.membersToNotify.push({
          name: memberObject.name,
          reason: member.reason,
        });
      });
    },
    async getMembersToAdd() {
      this.membersToAddList = [];
      let membersToAddArray = [];
      if (this.ticketData && this.ticketData.centerData) {
        const membersToAddData = await this.$store.dispatch(
          "getMembersDetailsInCounty",
          {
            state: this.ticketData.state,
            county: this.ticketData.county,
            url: this.ticketData.centerData.url,
            center: this.ticketData.centerData.center,
          }
        );
        if (membersToAddData.status !== "error") {
          membersToAddArray = membersToAddData.map(({ code, name }) => {
            return {
              mbcode: code,
              name,
              mbcode_name: `${code} - ${name}`,
            };
          });
          this.membersToAddList = membersToAddArray;
          await this.$store.commit("setMembersToAdd", this.membersToAddList);
        }
      }
    },
    closeDialog() {
      this.remarks = "";
      this.searchCountyMembers = null;
      this.selectedMembersToAdd = [];
      this.submitButtonClicked = false;
      this.$emit("closeNoResponseTicketDialog");
    },
    isNumber(evt) {
      const charCode = evt.which ? evt.which : evt.keyCode;
      return (charCode > 31 && (charCode < 48 || charCode > 57)) ? evt.preventDefault() : true;
    },
  },
  computed: {
    callerError() {
      const errors = [];
      if (!this.$v.caller.$dirty) return errors;
      !this.$v.caller.required && errors.push("Caller name is required");
      return errors;
    },
    contactError() {
      const errors = [];
      if (!this.$v.contact.$dirty) return errors;
      !this.$v.contact.required && errors.push("Contact name is required");
      return errors;
    },
    callerPhoneError() {
      const errors = [];
      if (!this.$v.callerPhone.$dirty) return errors;
      !this.$v.callerPhone.phone && errors.push("Enter a valid caller phone");
      return errors;
    },
    remarksErrors() {
      const errors = [];
      if (!this.$v.remarks.$dirty) return errors;
      !this.$v.remarks.maxLength && errors.push(this.$t("remarksMaxLength"));
      return errors;
    },
    contactPhoneError() {
      const errors = [];
      if (!this.$v.contactPhone.$dirty) return errors;
      !this.$v.contactPhone.phone && errors.push("Enter a valid contact phone");
      return errors;
    }
  },
};
</script>

<style lang="scss" scoped>
.v-select.v-input--dense .v-chip {
  margin: 8px 4px 0 4px;
}

.chip-content {
  max-width: 95%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.v-chip__close {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.white-bg-dialog .v-card {
  background-color: white !important;
  z-index: 2000;
}
</style>
