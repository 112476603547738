<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="facilities"
        class="elevation-1 no-sort"
        :hide-default-footer="true"
        disable-sort
      >
        <template v-slot:[`item.facilityType`]="{ item }">
          <span class="font-weight-medium">{{ item.facilityType }}</span>
        </template>

        <template v-slot:[`item.markedOrCleared`]="{ item }">
          <!-- Empty for now -->
          <span class="font-weight-medium">
            {{ getMarkedColData(item.facilityType).marked }} of
            {{ getMarkedColData(item.facilityType).total }}
          </span>
        </template>

        <template v-slot:[`item.notMarked`]="{ item }">
          <v-radio-group
            v-model="item.notMarked"
            row
            @change="handleSelection(item, 'notMarked')"
            :disabled="
              getMarkedColData(item.facilityType).marked === 0 &&
              getMarkedColData(item.facilityType).total === 0
            "
          >
            <v-radio label="" value="notMarked"></v-radio>
          </v-radio-group>
        </template>

        <template v-slot:[`item.mismarkedOrInaccurate`]="{ item }">
          <v-radio-group
            v-model="item.mismarkedOrInaccurate"
            row
            :disabled="
              getMarkedColData(item.facilityType).marked === 0 &&
              getMarkedColData(item.facilityType).total === 0
            "
            @change="handleSelection(item, 'mismarkedOrInaccurate')"
          >
            <v-radio label="" value="mismarkedOrInaccurate"></v-radio>
          </v-radio-group>
        </template>

        <template v-slot:[`item.incompleteOrPartiallyMarked`]="{ item }">
          <v-radio-group
            v-model="item.incompleteOrPartiallyMarked"
            row
            :disabled="
              getMarkedColData(item.facilityType).marked === 0 &&
              getMarkedColData(item.facilityType).total === 0
            "
            @change="handleSelection(item, 'incompleteOrPartiallyMarked')"
          >
            <v-radio label="" value="incompleteOrPartiallyMarked"></v-radio>
          </v-radio-group>
        </template>

        <template v-slot:[`item.unmarkedFacilityContactedOrDamaged`]="{ item }">
          <v-radio-group
            v-model="item.unmarkedFacilityContactedOrDamaged"
            row
            :disabled="
              getMarkedColData(item.facilityType).marked === 0 &&
              getMarkedColData(item.facilityType).total === 0
            "
            @change="handleSelection(item, 'unmarkedFacilityContactedOrDamaged')"
          >
            <v-radio label="" value="unmarkedFacilityContactedOrDamaged"></v-radio>
          </v-radio-group>
        </template>

        <template v-slot:[`item.removeReasonSelection`]="{ item }">
          <v-btn
            color="primary"
            @click="resetSelection(item)"
            :disabled="
              getMarkedColData(item.facilityType).marked === 0 &&
              getMarkedColData(item.facilityType).total === 0
            "
            >Reset</v-btn
          >
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      headers: [
        { text: "Facility Type", value: "facilityType", sortable: false },
        {
          text: "Marked or Cleared",
          value: "markedOrCleared",
          sortable: false,
        },
        { text: "Not Marked", value: "notMarked", sortable: false },
        {
          text: "Mismarked or Inaccurate",
          value: "mismarkedOrInaccurate",
          sortable: false,
        },
        {
          text: "Incomplete or Partially Marked",
          value: "incompleteOrPartiallyMarked",
          sortable: false,
        },
        {
          text: "Unmarked Facility Contacted or Damaged",
          value: "unmarkedFacilityContactedOrDamaged",
          sortable: false,
        },
        {
          text: "Remove Reason Selection",
          value: "removeReasonSelection",
          sortable: false,
        },
      ],
      facilities: [
        {
          facilityType: "ALL FACILITIES",
          notMarked: null,
          mismarkedOrInaccurate: null,
          incompleteOrPartiallyMarked: null,
          unmarkedFacilityContactedOrDamaged: null,
        },
        {
          facilityType: "COMMUNICATION (PHONE, CABLE TV, FIBER OPTICS)",
          notMarked: null,
          mismarkedOrInaccurate: null,
          incompleteOrPartiallyMarked: null,
          unmarkedFacilityContactedOrDamaged: null,
        },
        {
          facilityType: "SEWER & STORM DRAIN",
          notMarked: null,
          mismarkedOrInaccurate: null,
          incompleteOrPartiallyMarked: null,
          unmarkedFacilityContactedOrDamaged: null,
        },
        {
          facilityType: "WATER",
          notMarked: null,
          mismarkedOrInaccurate: null,
          incompleteOrPartiallyMarked: null,
          unmarkedFacilityContactedOrDamaged: null,
        },
        {
          facilityType: "GAS/OIL/PETROLEUM",
          notMarked: null,
          mismarkedOrInaccurate: null,
          incompleteOrPartiallyMarked: null,
          unmarkedFacilityContactedOrDamaged: null,
        },
        {
          facilityType: "POWER/ELECTRICAL",
          notMarked: null,
          mismarkedOrInaccurate: null,
          incompleteOrPartiallyMarked: null,
          unmarkedFacilityContactedOrDamaged: null,
        },
      ],
      reasons: {
        markedOrCleared: "MARKED OR CLEARED",
        notMarked: "NOT MARKED",
        mismarkedOrInaccurate: "MISMARKED OR INACCURATE",
        incompleteOrPartiallyMarked: "INCOMPLETE OR PARTIALLY MARKED",
        unmarkedFacilityContactedOrDamaged: "UNMARKED FACILITY CONTACTED OR DAMAGED",
      },
      facilitiesToNotify: [],
      groups: [
        {
          name: "COMMUNICATION (PHONE, CABLE TV, FIBER OPTICS)",
          keys: ["C", "P", "F"],
          count: 0,
        },
        { name: "SEWER & STORM DRAIN", keys: ["S", "D"], count: 0 },
        { name: "WATER", keys: ["W", "I"], count: 0 },
        { name: "GAS/OIL/PETROLEUM", keys: ["G"], count: 0 },
        { name: "POWER/ELECTRICAL", keys: ["E", "L", "T"], count: 0 },
      ],
    };
  },
  props: {
    nrspMembersData: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.markedColData();
  },
  computed: {
    allFacilityTypeCount() {
      let count = 0;
      for (const element of this.facilityTypeData) {
        if (element.marked === true) {
          count++;
        }
      }
      return { marked: count, total: this.facilityTypeData.length };
    },
    facilityTypeData() {
      let membersData = [];

      this.unmarkedMembers.forEach((element) => {
        let obj = {
          key: element.group_code,
          marked:
            element.response &&
            (element.response.split("")[0] === "1" ||
              element.response.split("")[0] === "2")
              ? true
              : false,
        };
        membersData.push(obj);
      });

      return membersData;
    },
    unmarkedMembers() {
      return this.nrspMembersData.filter(member => member?.response_due === null);
    }
  },

  methods: {
    handleSelection(selectedItem, selectedField) {
      const facilityObj = {
        facilityType: selectedItem.facilityType,
        reason: this.reasons[selectedField],
      };

      if (selectedItem.facilityType === "ALL FACILITIES") {
        this.facilitiesToNotify = [facilityObj];
        this.facilities.forEach((item) => {
          if (item.facilityType !== "ALL FACILITIES") {
            item.notMarked = null;
            item.mismarkedOrInaccurate = null;
            item.incompleteOrPartiallyMarked = null;
            item.unmarkedFacilityContactedOrDamaged = null;
          }
        });
      } else {
        const allFacilitiesItem = this.facilities.find(
          (item) => item.facilityType === "ALL FACILITIES"
        );
        if (allFacilitiesItem) {
          allFacilitiesItem.notMarked = null;
          allFacilitiesItem.mismarkedOrInaccurate = null;
          allFacilitiesItem.incompleteOrPartiallyMarked = null;
          allFacilitiesItem.unmarkedFacilityContactedOrDamaged = null;
        }

        const index = this.facilitiesToNotify.findIndex(
          (obj) => obj.facilityType === selectedItem.facilityType
        );

        if (index !== -1) {
          this.facilitiesToNotify.splice(index, 1);
        }

        this.facilitiesToNotify.push(facilityObj);

        const allFacilitiesIndex = this.facilitiesToNotify.findIndex(
          (obj) => obj.facilityType === "ALL FACILITIES"
        );
        if (allFacilitiesIndex !== -1) {
          this.facilitiesToNotify.splice(allFacilitiesIndex, 1);
        }
      }

      Object.keys(selectedItem).forEach((key) => {
        if (key !== "facilityType" && key !== selectedField) {
          selectedItem[key] = null;
        }
      });
      this.$emit("update-facilities-to-notify", this.facilitiesToNotify);
    },
    resetSelection(item) {
      item.notMarked = null;
      item.mismarkedOrInaccurate = null;
      item.incompleteOrPartiallyMarked = null;
      item.unmarkedFacilityContactedOrDamaged = null;
      const index = this.facilitiesToNotify.findIndex(
        (facility) => facility.facilityType === item.facilityType
      );
      if (this.facilitiesToNotify && index !== -1) {
        this.facilitiesToNotify.splice(index, 1);
      }
      this.$emit("update-facilities-to-notify", this.facilitiesToNotify);
    },
    markedColData() {
      this.groups.forEach((grp) => {
        this.facilityTypeData.forEach((item) => {
          if (grp.keys.some((key) => item.key.includes(key))) {
            grp.count++;
            if (item.marked) {
              grp.approved = (grp.approved || 0) + 1;
            }
          }
        });
      });
    },
    getMarkedColData(facilityType) {
      if (facilityType === "ALL FACILITIES") {
        return this.allFacilityTypeCount;
      }

      for (const element of this.groups) {
        if (element.name === facilityType) {
          return {
            marked: element.approved || 0,
            total: element.count,
          };
        }
      }

      return { marked: 0, total: 0 };
    },
  },
};
</script>

<style>
.no-sort .v-data-table-header th .v-data-table-header__icon {
  display: none;
}
</style>
