<template>
  <v-container>
    <v-card>
      <v-data-table
        :headers="headers"
        :items="nrspMembersData"
        class="elevation-1"
        :hide-default-footer="true"
        :items-per-page="-1"
        disable-sort
      >
        <template v-slot:[`item.mbcode`]="{ item }">
          <span class="font-weight-medium">{{ item.mbcode }}</span>
        </template>
        <template v-slot:[`item.name`]="{ item }">
          <span class="font-weight-medium">{{ item.name }}</span>
        </template>
        <template v-slot:[`item.group_code`]="{ item }">
          <span class="font-weight-medium">{{ item.group_code }}</span>
        </template>
        <template v-slot:[`item.response`]="{ item }">
          <v-chip
            :color="getChipColor(item.response)"
            :class="getTextColor(item.response)"
            @click="toggleResponseDescription(item.response, item.response_description)"
            :disabled="checkIfResponseDue(item.response_due) || item.response === null"
          >
            {{ item.response }}
          </v-chip>
        </template>
        <template v-slot:[`item.markedOrCleared`]="{ item }">
          <v-icon
            v-if="
              item.response &&
              (item.response.split('')[0] === '1' ||
                item.response.split('')[0] === '2')
            "
            :style="{ 'margin-right': $vuetify.breakpoint.smAndDown ? '25px' : '0' }"
            >mdi-check</v-icon
          >
          <v-icon v-else color="red"
          :style="{ 'margin-right': $vuetify.breakpoint.smAndDown ? '25px' : '0' }">mdi-block-helper</v-icon>
        </template>
        <template v-slot:[`item.notMarked`]="{ item }">
          <v-radio-group
            v-model="item.selection"
            row
            @click.native="toggleEpr(item, $event)"
            :disabled="checkIfResponseDue(item.response_due)"
          >
            <v-radio value="notMarked" ref="notMarkedRadio"></v-radio>
          </v-radio-group>
        </template>
        <template v-slot:[`item.mismarkedOrInaccurate`]="{ item }">
          <v-radio-group
            v-model="item.selection"
            row
            @click.native="toggleEpr(item, $event)"
            :disabled="checkIfResponseDue(item.response_due)"
          >
            <v-radio
              value="mismarkedOrInaccurate"
              ref="mismarkedOrInaccurateRadio"
            ></v-radio>
          </v-radio-group>
        </template>
        <template v-slot:[`item.incompleteOrPartiallyMarked`]="{ item }">
          <v-radio-group
            v-model="item.selection"
            row
            @click.native="toggleEpr(item, $event)"
            :disabled="checkIfResponseDue(item.response_due)"
          >
            <v-radio
              value="incompleteOrPartiallyMarked"
              ref="incompleteOrPartiallyMarkedRadio"
            ></v-radio>
          </v-radio-group>
        </template>
        <template v-slot:[`item.unmarkedFacilityContactedOrDamaged`]="{ item }">
          <v-radio-group
            v-model="item.selection"
            row
            @click.native="toggleEpr(item, $event)"
            :disabled="checkIfResponseDue(item.response_due)"
          >
            <v-radio
              value="unmarkedFacilityContactedOrDamaged"
              ref="unmarkedFacilityContactedOrDamagedRadio"
            ></v-radio>
          </v-radio-group>
        </template>
        <template v-slot:[`item.noShowForMeet`]="{ item }">
          <v-radio-group
            v-model="item.selection"
            row
            @click.native="toggleEpr(item, $event)"
            :disabled="checkIfResponseDue(item.response_due)"
          >
            <v-radio value="noShowForMeet" ref="noShowForMeetRadio"></v-radio>
          </v-radio-group>
        </template>
        <template v-slot:[`item.removeReasonSelection`]="{ item }">
          <v-btn
            v-if="$vuetify.breakpoint.smAndDown"
            class="text-none mb-3"
            color="primary"
            @click="resetAndRemoveSelection(item)"
            :disabled="checkIfResponseDue(item.response_due)"
            >Reset</v-btn
          >
          <v-btn
            v-else
            class="text-none"
            color="primary"
            @click="resetAndRemoveSelection(item)"
            :disabled="checkIfResponseDue(item.response_due)"
            >Reset</v-btn
          >
        </template>
      </v-data-table>
      <v-dialog max-width="500" v-model="toggleResponseDescriptionDialog">
        <template>
          <v-card title="Response Description">
            <v-card-text class="font-weight-medium pt-5 pb-0">
              {{ responseDescriptionText }}
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                class="text-none"
                @click="toggleResponseDescription()"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
      <v-dialog max-width="500" v-model="toggleEprDialog">
        <template>
          <v-card>
            <v-card-text class="font-weight-medium pt-5 pb-0">
                <div>
                  <span>{{responseEprText}}</span>
                </div>
              <v-divider class="my-2"></v-divider>
              <v-checkbox
                v-model="checkEpr"
                label="The EPR is not correct - check to activate the Continue button"
              ></v-checkbox>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!checkEpr"
                color="primary"
                class="text-none mr-1"
                @click="confirmEpr(true)"
                >Continue</v-btn
              >
              <v-btn
                color="primary"
                outlined
                class="text-none"
                @click="confirmEpr(false)"
                >Close</v-btn
              >
            </v-card-actions>
          </v-card>
        </template>
      </v-dialog>
    </v-card>
  </v-container>
</template>
<script>

import responseAdditionalReason from "@/static/noResponseAdditionalReason.json";

export default {
  props: {
    nrspMembersData: {
      type: Array,
      default: () => {
        return [];
      },
    },
    isMeetEligible: {
      type: Boolean,
      default: () => false,
    },
  },
  created() {
    this.checkAdditionalHeaders();
    this.nrspMembersData.forEach((member) => {
      this.$set(member, "selection", null);
    });
  },
  data() {
    return {
      headers: [
        { text: "Code", value: "mbcode" },
        { text: "Name", value: "name" },
        { text: "Facility Type(s)", value: "group_code" },
        { text: "Current Response", value: "response" },
        { text: "Marked or Cleared", value: "markedOrCleared" },
      ],
      reasons: {
        markedOrCleared: "MARKED OR CLEARED",
        notMarked: "NOT MARKED",
        mismarkedOrInaccurate: "MISMARKED OR INACCURATE",
        incompleteOrPartiallyMarked: "INCOMPLETE OR PARTIALLY MARKED",
        unmarked: "UNMARKED",
        facilityContactedOrDamaged: "FACILITY CONTACTED OR DAMAGED",
        unmarkedFacilityContactedOrDamaged:
          "UNMARKED FACILITY CONTACTED OR DAMAGED",
        noShowForMeet: "NO SHOW FOR MEET",
      },
      secondaryFunctionsAllowed: {},
      responseDescriptionText: "",
      toggleResponseDescriptionDialog: false,
      toggleEprDialog: false,
      responseEprText: "",
      currentItem: null,
      previousSelection: null,
      checkEpr: false,
      membersToNotify: [],
    };
  },
  methods: {
    getChipColor(response) {
      if (!response) return "transparent";
      const firstChar = response.split("")[0];
      if (firstChar === "1" || firstChar === "2") return "green";
      if (firstChar === "3") return "red";
      if (firstChar === "4") return "yellow";
      return "transparent";
    },
    getTextColor(response) {
      if (!response) return "transparent";
      if (response.split("")[0] < "4") return "white--text";
    },
    resetAndRemoveSelection(item) {
      item.selection = null;
      const index = this.membersToNotify.findIndex(
        (member) => member.memberCode === item.mbcode
      );
      if (this.membersToNotify && index !== -1) {
        this.membersToNotify.splice(index, 1);
      }
      this.$emit("update-members-data", this.membersToNotify);
    },
    toggleResponseDescription(responseCode, responseDescription) {
      this.toggleResponseDescriptionDialog =
        !this.toggleResponseDescriptionDialog;
      this.responseDescriptionText = `${responseCode}: ${responseDescription}`;
    },
    toggleEpr(item) {
      this.currentItem = item;
      this.pendingSelection = item.selection;
      this.previousSelection = item.selection;
      if (item?.response != "999" && item?.response != null) {
        this.toggleEprDialog = true;
        this.responseEprText = `The Electronic Positive Response (EPR) from ${item?.mbcode} - ${item?.name} for this request is ${item?.response} - ${item?.response_description}. ${this.formatReason(item?.response)}`;
      } else {
        this.confirmEpr(true);
      }
    },
    confirmEpr(isConfirmed) {
      if (isConfirmed && this.currentItem) {
        this.$set(this.currentItem, "selection", this.pendingSelection);
        this.handleMembersToNotify();
      } else if (this.currentItem) {
        this.$set(this.currentItem, "selection", null);
        this.resetAndRemoveSelection(this.currentItem);
      }
      this.toggleEprDialog = false;
      this.checkEpr = false;
    },
    formatReason(response) {
      const reason = responseAdditionalReason[response];
      
      // Find URLs in the reason and replace them with anchor tags
      const urlRegex = /(https?:\/\/[^\s]+)/g;
      const formattedReason = reason.replace(urlRegex, '<a href="$1" target="_blank">$1</a>');
      
      return formattedReason;
    },
    handleMembersToNotify() {
      if (this.checkIfMemberExists() !== -1) {
        this.membersToNotify[this.checkIfMemberExists()].reason =
          this.reasons[this.currentItem.selection];
      } else {
        const membersToNotifyObj = {
          memberCode: this.currentItem.mbcode,
          reason: this.reasons[this.currentItem.selection],
        };
        this.membersToNotify.push(membersToNotifyObj);
      }
      this.pendingSelection = null;
      this.currentItem = null;
      this.$emit("update-members-data", this.membersToNotify);
    },
    checkIfMemberExists(item) {
      return this.membersToNotify.findIndex(
        (member) =>
          member.memberCode == this.currentItem?.mbcode || item?.mbcode
      );
    },
    checkAdditionalHeaders() {
      const conditionalHeaders = [
        { text: "Not Marked", value: "notMarked" },
        { text: "Mismarked or Inaccurate", value: "mismarkedOrInaccurate" },
        {
          text: "Incomplete or Partially Marked",
          value: "incompleteOrPartiallyMarked",
        },
        {
          text: "Unmarked Facility Contacted or Damaged",
          value: "unmarkedFacilityContactedOrDamaged",
        },
      ];
      if (this.isMeetEligible) {
        this.headers.push({ text: "No Show for Meet", value: "noShowForMeet" });
      } else {
        this.headers.push(...conditionalHeaders);
      }
      this.headers.push({ text: "Remove Reason Selection", value: "removeReasonSelection" });
    },
    checkIfResponseDue(response_due) {
      return response_due === null ? false : true;
    },
  },
};
</script>
<style>
tbody tr:nth-of-type(even) {
  background-color: rgba(0, 0, 0, 0.05);
}
</style>
