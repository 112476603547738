var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    [
      _c(
        "v-card",
        [
          _c("v-data-table", {
            staticClass: "elevation-1",
            attrs: {
              headers: _vm.headers,
              items: _vm.nrspMembersData,
              "hide-default-footer": true,
              "items-per-page": -1,
              "disable-sort": "",
            },
            scopedSlots: _vm._u(
              [
                {
                  key: "item.mbcode",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "font-weight-medium" }, [
                        _vm._v(_vm._s(item.mbcode)),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.name",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "font-weight-medium" }, [
                        _vm._v(_vm._s(item.name)),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.group_code",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c("span", { staticClass: "font-weight-medium" }, [
                        _vm._v(_vm._s(item.group_code)),
                      ]),
                    ]
                  },
                },
                {
                  key: "item.response",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-chip",
                        {
                          class: _vm.getTextColor(item.response),
                          attrs: {
                            color: _vm.getChipColor(item.response),
                            disabled:
                              _vm.checkIfResponseDue(item.response_due) ||
                              item.response === null,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.toggleResponseDescription(
                                item.response,
                                item.response_description
                              )
                            },
                          },
                        },
                        [_vm._v(" " + _vm._s(item.response) + " ")]
                      ),
                    ]
                  },
                },
                {
                  key: "item.markedOrCleared",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      item.response &&
                      (item.response.split("")[0] === "1" ||
                        item.response.split("")[0] === "2")
                        ? _c(
                            "v-icon",
                            {
                              style: {
                                "margin-right": _vm.$vuetify.breakpoint
                                  .smAndDown
                                  ? "25px"
                                  : "0",
                              },
                            },
                            [_vm._v("mdi-check")]
                          )
                        : _c(
                            "v-icon",
                            {
                              style: {
                                "margin-right": _vm.$vuetify.breakpoint
                                  .smAndDown
                                  ? "25px"
                                  : "0",
                              },
                              attrs: { color: "red" },
                            },
                            [_vm._v("mdi-block-helper")]
                          ),
                    ]
                  },
                },
                {
                  key: "item.notMarked",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            disabled: _vm.checkIfResponseDue(item.response_due),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleEpr(item, $event)
                            },
                          },
                          model: {
                            value: item.selection,
                            callback: function ($$v) {
                              _vm.$set(item, "selection", $$v)
                            },
                            expression: "item.selection",
                          },
                        },
                        [
                          _c("v-radio", {
                            ref: "notMarkedRadio",
                            attrs: { value: "notMarked" },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.mismarkedOrInaccurate",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            disabled: _vm.checkIfResponseDue(item.response_due),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleEpr(item, $event)
                            },
                          },
                          model: {
                            value: item.selection,
                            callback: function ($$v) {
                              _vm.$set(item, "selection", $$v)
                            },
                            expression: "item.selection",
                          },
                        },
                        [
                          _c("v-radio", {
                            ref: "mismarkedOrInaccurateRadio",
                            attrs: { value: "mismarkedOrInaccurate" },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.incompleteOrPartiallyMarked",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            disabled: _vm.checkIfResponseDue(item.response_due),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleEpr(item, $event)
                            },
                          },
                          model: {
                            value: item.selection,
                            callback: function ($$v) {
                              _vm.$set(item, "selection", $$v)
                            },
                            expression: "item.selection",
                          },
                        },
                        [
                          _c("v-radio", {
                            ref: "incompleteOrPartiallyMarkedRadio",
                            attrs: { value: "incompleteOrPartiallyMarked" },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.unmarkedFacilityContactedOrDamaged",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            disabled: _vm.checkIfResponseDue(item.response_due),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleEpr(item, $event)
                            },
                          },
                          model: {
                            value: item.selection,
                            callback: function ($$v) {
                              _vm.$set(item, "selection", $$v)
                            },
                            expression: "item.selection",
                          },
                        },
                        [
                          _c("v-radio", {
                            ref: "unmarkedFacilityContactedOrDamagedRadio",
                            attrs: {
                              value: "unmarkedFacilityContactedOrDamaged",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.noShowForMeet",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            disabled: _vm.checkIfResponseDue(item.response_due),
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.toggleEpr(item, $event)
                            },
                          },
                          model: {
                            value: item.selection,
                            callback: function ($$v) {
                              _vm.$set(item, "selection", $$v)
                            },
                            expression: "item.selection",
                          },
                        },
                        [
                          _c("v-radio", {
                            ref: "noShowForMeetRadio",
                            attrs: { value: "noShowForMeet" },
                          }),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "item.removeReasonSelection",
                  fn: function (ref) {
                    var item = ref.item
                    return [
                      _vm.$vuetify.breakpoint.smAndDown
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "text-none mb-3",
                              attrs: {
                                color: "primary",
                                disabled: _vm.checkIfResponseDue(
                                  item.response_due
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetAndRemoveSelection(item)
                                },
                              },
                            },
                            [_vm._v("Reset")]
                          )
                        : _c(
                            "v-btn",
                            {
                              staticClass: "text-none",
                              attrs: {
                                color: "primary",
                                disabled: _vm.checkIfResponseDue(
                                  item.response_due
                                ),
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.resetAndRemoveSelection(item)
                                },
                              },
                            },
                            [_vm._v("Reset")]
                          ),
                    ]
                  },
                },
              ],
              null,
              true
            ),
          }),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.toggleResponseDescriptionDialog,
                callback: function ($$v) {
                  _vm.toggleResponseDescriptionDialog = $$v
                },
                expression: "toggleResponseDescriptionDialog",
              },
            },
            [
              [
                _c(
                  "v-card",
                  { attrs: { title: "Response Description" } },
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "font-weight-medium pt-5 pb-0" },
                      [_vm._v(" " + _vm._s(_vm.responseDescriptionText) + " ")]
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none",
                            attrs: { color: "primary" },
                            on: {
                              click: function ($event) {
                                return _vm.toggleResponseDescription()
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
          _c(
            "v-dialog",
            {
              attrs: { "max-width": "500" },
              model: {
                value: _vm.toggleEprDialog,
                callback: function ($$v) {
                  _vm.toggleEprDialog = $$v
                },
                expression: "toggleEprDialog",
              },
            },
            [
              [
                _c(
                  "v-card",
                  [
                    _c(
                      "v-card-text",
                      { staticClass: "font-weight-medium pt-5 pb-0" },
                      [
                        _c("div", [
                          _c("span", [_vm._v(_vm._s(_vm.responseEprText))]),
                        ]),
                        _c("v-divider", { staticClass: "my-2" }),
                        _c("v-checkbox", {
                          attrs: {
                            label:
                              "The EPR is not correct - check to activate the Continue button",
                          },
                          model: {
                            value: _vm.checkEpr,
                            callback: function ($$v) {
                              _vm.checkEpr = $$v
                            },
                            expression: "checkEpr",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "v-card-actions",
                      [
                        _c("v-spacer"),
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none mr-1",
                            attrs: {
                              disabled: !_vm.checkEpr,
                              color: "primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.confirmEpr(true)
                              },
                            },
                          },
                          [_vm._v("Continue")]
                        ),
                        _c(
                          "v-btn",
                          {
                            staticClass: "text-none",
                            attrs: { color: "primary", outlined: "" },
                            on: {
                              click: function ($event) {
                                return _vm.confirmEpr(false)
                              },
                            },
                          },
                          [_vm._v("Close")]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }