<template>
  <div>
    <div v-if="$vuetify.breakpoint.mdAndUp">
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!commentTicket"
        @click="commentTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("comment") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!retransmitTicket"
        @click="retransmitTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("retransmit") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!remarkTicket"
        @click="remarkTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("remark") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="150"
        :disabled="!noResponseTicket"
        @click="noResponseTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >No Response</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateTicketUtah"
        @click="updateTicketDialog = true"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelUtah"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <div v-else>
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!commentTicket"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        @click="commentTicketClicked"
        >{{ $t("comment") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!retransmitTicket"
        @click="retransmitTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("retransmit") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!remarkTicket"
        @click="remarkTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("remark") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="150"
        :disabled="!noResponseTicket"
        @click="noResponseTicketClicked"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >No Response</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        :disabled="!updateTicketUtah"
        @click="updateTicketDialog = true"
        class="text-none rounded-lg font-weight-regular mt-2 white--text mr-4"
        >{{ $t("update") }}</v-btn
      >
      <v-btn
        color="#1E853A"
        width="115"
        class="text-none rounded-lg font-weight-regular mt-2 white--text"
        :disabled="!cancelUtah"
        @click="cancelTicket"
        >{{ $t("cancel") }}</v-btn
      >
    </div>
    <CancelTicketDialogUtah
      v-if="cancelTicketDialog"
      :ticket-data="ticketData"
      :dialog="cancelTicketDialog"
      @cancelTicketSubmit="cancelTicketSubmit"
      @closeDialog="closeCancelTicketDialog"
      @cancelTicketSuccess="cancelTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <CommentTicketDialogUtah
      v-if="commentTicketDialog"
      :ticket-data="ticketData"
      :dialog="commentTicketDialog"
      @commentTicketSubmit="commentTicketSubmit"
      @closeDialog="closeCommentTicketDialog"
      @commentTicketSuccess="commentTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <RemarkTicketDialogUtah
      v-if="remarkTicketDialog"
      :ticket-data="ticketData"
      :dialog="remarkTicketDialog"
      @remarkTicketSubmit="remarkTicketSubmit"
      @closeRemarkDialog="closeRemarkTicketDialog"
      @remarkTicketSuccess="remarkTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <RetransmitTicketDialogUtah
      v-if="retransmitTicketDialog"
      :ticket-data="ticketData"
      :dialog="retransmitTicketDialog"
      @retransmitTicketSubmit="retransmitTicketSubmit"
      @closeRetransmitDialog="closeRetransmitTicketDialog"
      @retransmitTicketSuccess="retransmitTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <NoResponseDialogUtah
      v-if="noResponseTicketDialog"
      :secondary-functions-allowed-data="secondaryFunctionsAllowed"
      :is-meet-eligible="isMeetEligible"
      :dialog="noResponseTicketDialog"
      :ticket-data="ticketDetails"
      @noResponseTicketSubmit="noResponseTicketSubmit"
      @closeNoResponseTicketDialog="closeNoResponseTicketDialog"
      @noResponseTicketSuccess="noResponseTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <UpdateTicketDialogUtah
      :dialog="updateTicketDialog"
      :ticket-data="ticketData"
      @updateTicketDetails="updateTicketDetails"
      @closeDialog="closeUpdateTicketDialog"
      @commentTicketSuccess="commentUpdateTicketSuccess"
      @errorSubmitTicket="errorSubmitTicket"
    />
    <ErrorMessage
      v-if="isError"
      :error-code="errorCode"
      :error-message="errorMessage"
      @ticketScreen="isError = false"
    />
  </div>
</template>

<script>
import moment from "moment";
import EditTicket from "@/mixins/EditTicket";
import TicketSecondaryFunctions from "@/mixins/TicketSecondaryFunctions";
import NoResponseDialogUtah from "./NoResponseDialogUtah.vue";
export default {
  mixins: [EditTicket, TicketSecondaryFunctions],
  name: "TickDetails",
  components: {
    ErrorMessage: () => import("../../../../molecules/ErrorMessage.vue"),
    CancelTicketDialogUtah: () => import("./CancelTicketDialogUtah.vue"),
    CommentTicketDialogUtah: () => import("./CommentTicketDialog.vue"),
    RemarkTicketDialogUtah: () => import("./RemarkTicketDialogUtah.vue"),
    RetransmitTicketDialogUtah: () => import("./RetransmitTicketDialog.vue"),
    NoResponseDialogUtah: () => import("./NoResponseDialogUtah.vue"),
    UpdateTicketDialogUtah: () => import("./UpdateTicketDialogUtah.vue"),
  },
  props: {
    ticketDetails: {
      type: Object,
      default: () => {},
    },
  },
  data: () => ({
    cancelUtah: false,
    updateTicketDialog: false,
    updateTicketUtah: false,
    noResponseTicket: false,
    retransmitTicket: false,
    commentTicket: false,
    remarkTicket: false,
    commentCO: false,
    dialog: true,
    cancelTicketDialog: false,
    noResponseTicketDialog: false,
    retransmitTicketDialog: false,
    commentTicketDialog: false,
    remarkTicketDialog: false,
    ticketData: {},
    errorCode: "",
    errorMessage: "",
    isError: false,
    secondaryFunctionsAllowed: {},
    isMeetEligible: false,
  }),
  computed: {},
  async created() {
    this.moment = moment;
  },
  async mounted() {
    const ticket_id = this.ticketDetails.ticket_id;
    const ticketResp = await this.$store.dispatch("getTicketById", {
      ticket_id: ticket_id,
    });
    this.ticketData = Object.assign({}, this.ticketDetails, ticketResp[0]);
    await this.checkSecondaryFunctionsEligibility();
  },
  methods: {
    errorSubmitTicket(data) {
      this.errorCode = data.errorCode.toString();
      this.errorMessage = data.error;
      this.isError = true;
    },
    retransmitTicketClicked() {
      this.retransmitTicketDialog = true;
    },
    remarkTicketClicked() {
      this.remarkTicketDialog = true;
    },
    closeRetransmitTicketDialog() {
      this.retransmitTicketDialog = false;
    },
    retransmitTicketSubmit() {
      this.retransmitTicketDialog = false;
    },
    async retransmitTicketSuccess(
      retransmitTicketNumber,
      retransmitTicketRevision
    ) {
      this.retransmitTicketDialog = false;
      this.$emit(
        "retransmitTicketSuccess",
        retransmitTicketNumber,
        retransmitTicketRevision
      );
    },
    closeRemarkTicketDialog() {
      this.remarkTicketDialog = false;
    },
    remarkTicketSubmit() {
      this.remarkTicketDialog = false;
    },
    async remarkTicketSuccess(remarkTicketNumber, remarkTicketRevision) {
      this.remarkTicketDialog = false;
      this.$emit(
        "remarkTicketSuccess",
        remarkTicketNumber,
        remarkTicketRevision
      );
    },

    async updateTicketDetails(editType, item) {
      this.ticketDetails.options = this.ticketData.options;
      this.ticketDetails.utahDetails = item
      await this.updateTicket(this.ticketDetails, editType);
      this.$router.push("/createTicket").catch();
    },
    cancelTicket() {
      this.cancelTicketDialog = true;
    },
    closeCancelTicketDialog() {
      this.cancelTicketDialog = false;
    },
    cancelTicketSubmit() {
      this.cancelTicketDialog = false;
    },
    async cancelTicketSuccess(cancelTicketNumber, cancelTicketRevision) {
      this.cancelTicketDialog = false;
      this.$emit(
        "cancelTicketSuccess",
        cancelTicketNumber,
        cancelTicketRevision
      );
    },
    async noResponseTicketClicked() {
      const secondaryFunctionsAllowedData = await this.$store.dispatch(
        "secondaryFunctionsAllowed",
        {
          ticket: this.ticketDetails.center_ticket_id,
          revision: this.ticketDetails.revision,
          ticket_type: "NRSP",
          center: this.ticketDetails.centerData.center,
        }
      );
      this.secondaryFunctionsAllowed = secondaryFunctionsAllowedData;
      this.isMeetEligible =
        secondaryFunctionsAllowedData?.isEligible == "meet" ? true : false;
      this.noResponseTicketDialog = true;
    },
    closeNoResponseTicketDialog() {
      this.noResponseTicketDialog = false;
    },
    noResponseTicketSubmit() {
      this.noResponseTicketDialog = false;
    },
    async noResponseTicketSuccess(ticketNumber, ticketRevision) {
      this.noResponseTicketDialog = false;
      this.$emit("noResponseTicketSuccess", ticketNumber, ticketRevision);
    },

    commentTicketClicked() {
      this.commentTicketDialog = true;
    },
    closeCommentTicketDialog() {
      this.commentTicketDialog = false;
    },
    commentTicketSubmit() {
      this.commentTicketDialog = false;
    },
    async commentTicketSuccess(commentTicketNumber, commentTicketRevision) {
      this.commentTicketDialog = false;
      this.$emit(
        "commentTicketSuccess",
        commentTicketNumber,
        commentTicketRevision
      );
    },
    async commentUpdateTicketSuccess(
      commentTicketNumber,
      commentTicketRevision
    ) {
      this.updateTicketDialog = false;
      this.$emit(
        "commentTicketSuccess",
        commentTicketNumber,
        commentTicketRevision
      );
    },
    closeUpdateTicketDialog() {
      this.updateTicketDialog = false;
    },
  },
  async getTicketOptions() {
    const ticket_id = this.ticketDetails.ticket_id;
    const ticketResp = await this.$store.dispatch("getTicketById", {
      ticket_id: ticket_id,
    });
    if (ticketResp.status !== "error") {
      this.ticketData.options = ticketResp.options;
    }
  },
};
</script>

<style lang="scss" scoped></style>
